<template>
  <AppPageCard>
    <template #card-title> Personal Leave </template>
    <template #card-toolbar>
      <!-- begin: b-nav tabs  -->
      <b-nav pills class="nav-light-primary font-weight-bolder">
        <b-nav-item to="entitlement" exact exact-active-class="active"
          >{{ $t("EDULEAVE.APP") }}
        </b-nav-item>
        <b-nav-item to="application" exact exact-active-class="active"
          >{{ $t("EDULEAVE.APPSTATUS") }}
        </b-nav-item>
      </b-nav>
      <!-- end: b-nav tabs  -->
    </template>
    <router-view></router-view>
  </AppPageCard>
</template>

<script>
import AppPageCard from "@/modules/core/components/shared/card/AppPageCard";
export default {
  name: "PersonalLeavePage",
  components: {
    AppPageCard,
  },
};
</script>

<style scoped></style>
